const placeholderFolders = {
    downtown: 'downtown',
    etobicoke: 'etobicoke',
    'north-york': 'north_york',
    'east-end': 'midtown',
    'west-end': 'midtown',
    'east-york': 'north_york',
    scarborough: 'scarborough',
    midtown: 'midtown',
    'york-crosstown': 'midtown',
};

const placeholderSizes = {
    downtown: 81,
    etobicoke: 17,
    north_york: 21,
    scarborough: 15,
    midtown: 29,
};

export default function getBuildingImage(building) {
    const placeholderFolder = placeholderFolders[building?.sub_localities?.slug || 'downtown'];
    const placeholderModulo = placeholderSizes[placeholderFolder];
    return {
        url:
            building.cover_image ||
            `/placeholders/${placeholderFolder}/${(building.id % placeholderModulo) + 1}.webp` ||
            '/building.jpg',
        isPlaceholder: !building.cover_image,
    };
}
